<template>
  <div class="user">
    <!--     <div class="mantil">个人资料</div> -->
    <!-- 大盒子 -->
    <!-- @tab-click="handleClick" -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="个人资料" name="first">
        <div class="user">
          <div class="basic">
            <!-- 缩宽90% -->
            <div class="basics">
              <!-- 跳转内容 b  1 修改信息-->

              <div v-show="b == 1" class="basicsCent">
                <!-- 左侧表单 -->
                <div class="basicsCentLeft">
                  <div>
                    <div class="dis_flex_start">
                      <div class="avatar-uploader-sheji">
                        <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar">
                        <img v-else src="@/assets/img/partner/tou.png" class="avatar">
                      </div>
                      <el-upload ref="zIcon" :show-file-list="false" name="upload" :action="imgAction()"
                        :headers="Domainjwt" :on-success="thumbnailSuccessiconUrl"
                        :before-upload="field133BeforeUpload">
                        <span class="upload">上传头像</span>
                      </el-upload>
                    </div>
                  </div>
                  <el-form ref="upDataForm" :model="ruleForm" :rules="rules" label-width="100px" label-position="top"
                    class="demo-ruleForm">
                    <el-form-item label="账号">
                      <el-input v-model="ruleForm.account" :disabled="true" />{{
                      }}</el-form-item>
                    <el-form-item label="真实姓名" prop="nickName ">
                      <el-input v-model="ruleForm.nickName" placeholder="请输入您的真实姓名" />
                    </el-form-item>
                    <!-- <el-form-item label="手机号码：" prop="mobile">
                  <el-input
                    v-model="ruleForm.mobile"
                    placeholder="请输入您的手机号码"
                    :disabled="true"
                  ></el-input>
                </el-form-item> -->
                    <el-form-item label="性别" prop="sex">
                      <el-radio-group v-model="ruleForm.sex">
                        <el-radio label="男" />
                        <el-radio label="女" />
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="生日">
                      <el-date-picker v-model="ruleForm.birthday" type="date" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" placeholder="选择日期" />
                    </el-form-item>
                    <el-form-item label="个性签名" style="width: 590px">
                      <el-input placeholder="请输入" v-model="ruleForm.autograph" :rows="1" :maxlength="20"
                        show-word-limit />
                    </el-form-item>
                    <!-- <el-form-item label="所在地区：">
                  <area-select
                    type="all"
                    :level="2"
                    v-model="vmcity"
                    :data="pcaa"
                    style="
                      display: flex;
                      width: 440px;
                      height: 40px;
                      line-height: 15px;
                    "
                  ></area-select>
                </el-form-item> -->
                    <!-- <el-form-item label="详细地址：" prop="address">
                  <el-input v-model="ruleForm.address"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱：">
                  <el-input v-model="ruleForm.email"></el-input>
                </el-form-item> -->
                    <div class="colleges-keep" @click="xiuGai">保存</div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="isXiaoQingWang" label="地址管理" name="second">
        <div class="address">
          <div v-if="!UserAddress || UserAddress.length == 0" class="addressAdd">
            <el-button class="addBtn" @click="editAddress">+ 新增地址</el-button>
          </div>
          <div v-for="(item, index) in UserAddress" :key="index" class="addressBox">
            <div class="addressBox_left">
              <img class="address-default" src="@/assets/img/textmall/default_g.png">
              <div class="title">
                <span style="display: inline-block; width: 214px">收货人：{{ item.reveiveName }}</span>
                <span style="display: inline-block">联系电话：{{ item.mobile }}</span>
              </div>
              <div class="title" style="margin-top: 23px">
                <span style="display: inline-block">收货地址：{{ item.cityName + item.address }}</span>
              </div>
            </div>
            <div class="addressBox_right">
              <!-- <span @click="setDefaultStuShopAddress(item)">
                <img style="width: 14px; height: 14px;" v-if="item.isDefault == 1" src="@/assets/img/textmall/moren.png"
                  alt="">
                <div v-else class="feimoren" />
                <span v-if="item.isDefault == 1" style="color: #006DE7;">设为默认地址</span>
                <span v-else>设为默认地址</span>
              </span> -->
              <span style="color: #006DE7;display: flex; align-items: center;" @click="updateClick(item)">
                <img style="width: 14px; height: 14px; margin-right: 5px;" src="@/assets/img/textmall/edit_big.png"
                  alt="">
                编辑</span>
              <!-- <span style="color: #D81E06;display: flex; align-items: center;"> <img
                  style="width: 14px; height: 14px; margin-right: 5px;" src="@/assets/img/textmall/delete.png"
                  alt="">删除</span> -->
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 修改手机号绑定 -->
    <el-dialog title="修改绑定" :visible.sync="dialogVisible" width="30%">
      <phone-change v-if="dialogVisible" @isOk="dialogVisible = false" />
    </el-dialog>

    <el-dialog class="addressDialog" center title="新增收货地址" :visible.sync="addressDialog">
      <address-dialog :addressForm="addressForm" @save="save" @cancelClick="cancelClick" v-if="addressDialog" />
    </el-dialog>
  </div>
</template>
<script>
import { imagecode, selectCode } from '@/api/register'
import { pca, pcaa } from 'area-data' // 城市数据
import { getPcUser, updatePcUser, updatePcUserPassword } from '@/api/user'
import {
  addStuShopAddress,
  getUserAddress,
  setDefaultStuShopAddress,
  delStuShopAddress,
  updateStuShopAddress
} from '@/api/mykecheng'
import PhoneChange from '@/components/mine/phoneChange.vue'
import addressDialog from '@/views/seventhPage/mine/myPage/addressDialog.vue'
import { setInfo, setToken, getInfo, getDomainToken } from '@/api/cookies'

export default {
  components: { PhoneChange, addressDialog },

  data() {
    const validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请确认您的新密码'))
      } else if (value !== this.form.passwords) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userInfo: null,
      vmcity: [], // 地址
      UserAddress: [], // 地址
      pca: pca,
      pcaa: pcaa,
      addressForm: {},
      isXiaoQingWang: false,
      name: '',
      names: '',
      activeName: 'first',
      addressDialog: false,
      Domainjwt: {
        Domainjwt: getDomainToken()
      },
      imageUrl: '',
      user: '',
      tabIndex: 1,
      // 全部订单
      b: 1,
      captcha: '',
      ruleForm: {},
      detail: {
        img: '',
        nickName: ''
      },

      form: {
        password: '',
        passwords: '',
        imgCode: ''
      },

      rules: {
        nickName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ],
        gender: [
          { required: true, message: '性别不能为空', trigger: 'change' }
        ],
        email: [
          { required: true, message: '电子邮箱不能为空', trigger: 'blur' }
        ],
        password: [
          {
            required: true,
            message: '请输入您现在的登录密码',
            trigger: 'blur'
          },
          { min: 6, message: '密码长度不得小于6位数', trigger: 'blur' }
        ],
        passwords: [
          { required: true, message: '请输入您的新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不得小于6位数', trigger: 'blur' }
        ],
        passwordss: [
          { required: true, validator: validatePass2, trigger: 'blur' },
          { min: 6, message: '密码长度不得小于6位数', trigger: 'blur' }
        ],
        imgCode: [
          { required: true, message: '请输入右边验证码', trigger: 'blur' }
        ]
      },
      type: {
        sjh: 'sjh',
        qq: 'qq',
        wx: 'wx'
      },
      title: '修改绑定',
      titles: '已绑定',
      /* 修改绑定手机号 */
      dialogVisible: false,
      dialogVisibles: false,
      fileList: [
        {
          name: '默认头像.jpeg',
          url: 'https://staticfile.xlcwx.com/avatar/icon1.png'
        }
      ],
      imgVal: 99,
      imgList: [
        'https://staticfile.xlcwx.com/avatar/icon0.png',
        'https://staticfile.xlcwx.com/avatar/icon1.png',
        'https://staticfile.xlcwx.com/avatar/icon2.png',
        'https://staticfile.xlcwx.com/avatar/icon3.png',
        'https://staticfile.xlcwx.com/avatar/icon4.png',
        'https://staticfile.xlcwx.com/avatar/icon5.png',
        'https://staticfile.xlcwx.com/avatar/icon6.png',
        'https://staticfile.xlcwx.com/avatar/icon7.png'
      ]
    }
  },
  async created() {
    this.userInfo = await getInfo()
    this.isXiaoQingWang = localStorage.getItem('isXiaoQingWang') ? localStorage.getItem('isXiaoQingWang') : false

    this.loadgetPcUser()
    this.loadCaptcha()
    this.getUserAddress()
    if (!this.userInfo) {
      return
    }
    if (this.userInfo.mobile) {
      this.title = '修改绑定'
      this.titles = '已绑定'
    } else {
      this.title = '立即绑定'
      this.titles = '未绑定'
    }

    // 调用验证码接口方法

    // 调用基本资料接口方法
  },
  methods: {
    // 固定图片选择
    imgClack(wx) {
      this.imgVal = wx
    },
    async getUserAddress() {
      const res = await getUserAddress()
      if (res.code == 0) {
        console.log(res)
        this.UserAddress = res.msg
      }
    },
    // 设置默认地址
    async setDefaultStuShopAddress(val) {
      const params = {
        addressId: val.id,
        isDefault: Number(!val.isDefault)
      }
      const res = await setDefaultStuShopAddress(params)
      if (res.code == 0) {
        console.log(res)
        this.getUserAddress()
        // this.UserAddress = res.msg
      }
    },
    // 编辑收货地址
    editAddress() {
      this.addressForm = {};

      this.addressDialog = true
    },
    // 修改
    updateClick(val) {
      this.addressForm = val;
      this.add = val.address;
      this.addressDialog = true;
    },
    // 取消
    cancelClick() {
      this.addressList.forEach((item) => {
        if (item.id === this.addressForm.id) {
          item.address = this.add;
        }
      });
      this.addressForm = {};
      this.add = "";
      this.addressDialog = false;
    },
    // 删除
    async deleteClick(val) {
      const res = await delStuShopAddress(val.id);
      if (res.code === 0) {
        this.getUserAddress();
      }
    },
    // 调用验证码接口方法
    loadCaptcha() {
      imagecode().then((res) => {
        this.captcha = `data: image/jpeg;base64,${btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )}`
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibles = true
    },
    // 调用基本资料接口方法
    loadgetPcUser() {
      getPcUser(this.userInfo.id).then((res) => {
        localStorage.setItem('userId', res.msg.id)
        setInfo(res.msg)
        this.ruleForm = res.msg
        this.vmcity = res.msg.provinceAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.provinceAddress)))
          : this.vmcity.concat([])
        this.vmcity = res.msg.cityAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.cityAddress)))
          : this.vmcity.concat([])
        this.vmcity = res.msg.areaAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.areaAddress)))
          : this.vmcity.concat([])
        this.$forceUpdate()

        if (this.ruleForm.img != this.userInfo.img) {
          // 页面强制刷新
          window.location.reload()
        }
      })
      this.$forceUpdate()
    },
    // 基本资料 修改信息
    async xiuGai() {
      if (!this.ruleForm.nickName) {
        this.$message({
          message: '请输入姓名',
          type: 'error'
        })
        return
      }
      this.$refs.upDataForm.validate(async (valid) => {
        if (valid) {
          if (this.imgVal != 99) {
            this.ruleForm.img = this.imgList[this.imgVal]
          }
          updatePcUser(
            this.ruleForm.id,
            this.ruleForm.userName,
            this.ruleForm.nickName,
            this.ruleForm.mobile,
            this.ruleForm.sex,
            this.ruleForm.address,
            this.vmcity[0],
            this.ruleForm.img,
            this.vmcity[1],
            this.vmcity[2],
            this.ruleForm.email,
            this.ruleForm.birthday,
            this.ruleForm.autograph
          ).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              getPcUser(this.userInfo.id).then((res) => {
                localStorage.setItem('userId', res.msg.id)
                setInfo(res.msg)
              })

              // 页面强制刷新
              setTimeout(function () {
                // window.location.reload();
              }, 2000)
            }
          })
        }
      })
    },
    // 基本资料 修改密码
    xiuGaimima() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          if (this.form.passwords == this.form.passwordss) {
            selectCode(this.form.imgCode).then((res) => {
              if (res.code != 0) {
                this.$message.error('请输入正确的验证码')
                return
              } else {
                updatePcUserPassword(
                  this.ruleForm.id,
                  this.form.password,
                  this.form.passwords,
                  this.form.imgCode
                ).then((res) => {
                  if (res.code == 0) {
                    this.form.password = ''
                    this.form.passwords = ''
                    this.form.imgCode = ''
                    this.loadCaptcha()
                    this.$message.success(res.msg)
                  } else {
                    this.$message.error(res.msg)
                  }
                })
              }
            })
          } else {
            this.$message.error('请输入相同的新密码')
          }
        }
      })
    },
    /* 标签页修改 */
    tabChange(val) {
      this.b = val
    },
    async save(val) {
      const params = Object.assign(val, { orderId: this.detail.id });
      if (val.id) {
        const res = await updateStuShopAddress(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.addressDialog = false;
        }
        this.getUserAddress();
      } else {
        const res = await addStuShopAddress(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.addressDialog = false;
        }
        this.getUserAddress();
      }
    },
    // 文件上传成功时的钩子
    thumbnailSuccessiconUrl(res, file) {
      if (res.isSuccess) {
        this.ruleForm.img = res.path
      } else {
        this.$message.error(res.error)
        Vue.prototype.imgClearn('zIcon', this)
      }
      this.$forceUpdate()
    },
    // 文件上传大小校验
    field133BeforeUpload(file) {
      const isRightSize = file.size / 1024 / 1024 < 10
      if (!isRightSize) {
        this.$message.error('文件大小超过 10MB')
      }
      return isRightSize
    }
  }
}
</script>
<style lang="less" scoped>
.user {
  width: 100%;
  margin: 0 auto;
}

.demo-ruleForm {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;

  .el-form-item {
    width: 270px;
    margin-right: 50px;
    margin-bottom: 28px;
  }
}

.addressDialog {
  display: flex;
  align-items: center;
  justify-content: center;

  /deep/ .el-dialog {
    width: 580px;
    height: 412px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
  }

  /deep/ .el-dialog__header {
    padding-bottom: 33px;
  }
}

.address {
  .addressAdd {
    margin-top: 9px;

    .addBtn {
      width: 110px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #006DE7;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #006DE7;
    }
  }

  .addressBox {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding-top: 26px;
    margin-bottom: 26px;
    padding-left: 23px;
    width: 996px;
    height: 110px;
    // background: skyblue; // #ffffff;;
    background-image: url("~@/assets/img/textmall/line.png");
    background-repeat: no-repeat;
    background-size: 996px 4px;
    background-position: bottom;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    .addressBox_left {
      flex: 1;

      .address-default {
        width: 34px;
        position: absolute;
        top: 0;
        left: 0px;
      }

      .title {
        position: relative;
        font-size: 16px;
        height: 16px;
        padding-top: 0;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;

        span {
          position: absolute;
          top: 0;
          display: inline-block;
          height: 16px;
          line-height: 16px;

          &:nth-child(2) {
            left: 214px;
          }
        }
      }
    }

    .addressBox_right {
      padding-top: 39px;
      display: flex;

      span {
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }

      >span {
        margin-right: 30px;

      }

      img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .feimoren {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: #ffffff;
        opacity: 1;
        border-radius: 50%;
        border: 1px solid #999999;
      }
    }
  }
}

.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

.el-input.el-input__inner {
  width: 100px;
}

.choose {
  border: 2px solid rgba(31, 150, 241, 1);
}

.chooseNot {
  border: 2px solid #fff;
}

.tips {
  width: 100%;
  height: auto;
  color: rgba(123, 129, 150, 1);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.file-upload {
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  color: #ff4027;
  text-align: center;
  line-height: 26px;
  margin: 10px 0 auto auto;
}

.file-upload-input {
  background-color: transparent;
  width: auto;
  height: 50px;
  cursor: pointer;
}

.basic {
  width: 100%;
  max-height: 710px;
  background: #ffffff;
  padding-bottom: 20px;

  // 缩宽
  .basics {
    width: 100%;
    height: 100%;
    margin: auto;

    // 标题
    p {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
    }

    // 跳转盒子
    .basicsName {
      width: 271px;
      height: 40px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .basicsNames {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .basicsNamesTop {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          cursor: pointer;
          color: #999999;
        }

        .basicsNamesTops {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .basicsNamesCen {
          width: 24px;
          height: 2px;
          margin-top: 14px;
          background: #ef0d0d;
          border-radius: 1px;
        }
      }
    }

    // 跳转内容 b = 1 修改信息 b = 2 修改信息密码
    .basicsCent {
      width: 100%;
      height: auto;
      // border-top: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      // 左侧
      .basicsCentLeft {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        margin-top: 20px;

        .el-form {
          .colleges-keep {
            width: 150px;
            height: 50px;
            background: linear-gradient(90deg, #0270e8 0%, #21b4f5 100%);
            border-radius: 6px 6px 6px 6px;
            opacity: 1;
            font-size: 16px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 50px;
            margin-top: 53px;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      // 右侧
      .basicsCentRight {
        width: 390px;
        height: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        // 图片
        img {
          width: 140px;
          height: 140px;
        }

        // 上传按钮
        .filet {
          width: 110px;
          height: 36px;
          margin-top: 20px;
          background: #ffd4b0;
          margin-bottom: 14px;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
          cursor: pointer;
          border: 1px solid #ffd4b0;
        }

        // 灰色提示字体
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a8abbe;
        }
      }
    }

    // 跳转内容 b = 3 账号绑定
    .basicsCenst {
      width: 100%;
      height: auto;
      border-top: 1px solid #eeeeee;

      // 绑定盒子
      .basicsCents {
        width: 940px;
        height: 90px;
        background: #f5f5f5;
        border-radius: 4px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        // 缩宽
        .basicsCentss {
          width: 880px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          // 左侧
          .bdLeft {
            width: auto;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            // 图片
            img {
              width: 50px;
              height: 50px;
            }

            // 字
            p {
              line-height: 50px;
              margin-left: 20px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }

          // 解除绑定按钮
          .bdRight {
            width: 120px;
            height: 42px;
            background: #ffffff;
            border: 1px solid #ff4027;
            border-radius: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff4027;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.avatar-uploader-sheji {
  border: none;
  background: none;
  width: 100px;
  margin-right: 20px;
  margin-bottom: 25px;
}

.upload {
  background: #F5F7FA;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  color: #666666;
}

.avatar-uploader-sheji img {
  width: 100px;
  height: 100px;
  // border-radius: 50%;
  border-radius: 8px;
  background-size: cover;
  margin: 0 auto;
}

/deep/ .el-upload {
  display: flex;
  height: 100%;
  align-items: center;
  // margin: 0 auto;
}

.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

/deep/ .el-form-item__label {
  padding: 0;
}

.el-date-editor.el-input {
  width: 270px;
}
</style>
